import React from 'react';
import { Link } from 'gatsby';
import { StaticQuery, graphql } from 'gatsby';



const RelatedServices = props => (
    <div className="related-services container pb-6">
        <h2 className="title">Consulta otros de nuestros servicios</h2>
        <div className="row">
            {props.data.allMarkdownRemark.edges
                .filter(edge => edge.node.frontmatter.path != props.avoidPath)
                .map(edge => (
                    <div key={edge.node.frontmatter.path} className="col-12 col-md-4 mb-1">
                        <div className="card service service-teaser">
                            <div className="card-content">
                                <h2>
                                    <Link to={edge.node.frontmatter.path}>{edge.node.frontmatter.title}</Link>
                                </h2>
                                <p>{edge.node.excerpt}</p>
                            </div>
                        </div>
                    </div>
                ))}
        </div>
    </div>
);

export default props => (
    <StaticQuery
        query={graphql`
      query {
        allMarkdownRemark(
          filter: { fileAbsolutePath: { regex: "/servicios/" } }
          sort: { fields: [frontmatter___date], order: DESC }
        ) {
          edges {
            node {
              frontmatter {
                path 
                title
              }
              excerpt
            }
          }
        }
      }
      `}
        render={data => <RelatedServices data={data} avoidPath={props.avoidPath} />}
    />
);